import React from 'react';

import { Session } from 'app/models/session';
import { AvailableDocumentProperty } from 'app/models/available-document-property';

export interface AppContextValue {
  session: Session | null;
  availableProperties: {
    isLoaded: boolean;
    value: AvailableDocumentProperty[];
    reload: () => Promise<void>;
  };
}

export const AppContext = React.createContext<AppContextValue>({
  session: null,
  availableProperties: {
    isLoaded: false,
    value: [],
    reload: async () => {},
  },
});
