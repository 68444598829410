import { notification } from 'antd';
import { AxiosError } from 'axios';

import { i18n } from 'app/i18n-config';

export const handleError = (error: any) => {
  console.error(error);
  notification.error({
    message: i18n.t('common.errorOccurred'),
    description:
      error instanceof AxiosError
        ? `${error.message} (${error.response?.statusText})`
        : `${error}`,
  });
};
