import { CancelToken } from 'axios';

import { axiosInstance } from 'app/constants/axios-instance';
import { DocumentList } from 'app/models/document-list';
import { DocumentCreate } from 'app/models/document-create';
import { DocumentUpdate } from 'app/models/document-update';
import { transformJsonRequest } from 'app/utils/transform-json-request';
import { DocumentPreview } from 'app/models/document-preview';
import { DocumentPreviewList } from 'app/models/document-preview-list';
import { AdditionalDocumentUpdate } from 'app/models/additional-document-update';
import { LocaleType } from 'app/models/locale-type';
import { AdditionalDocumentType } from 'app/models/additional-document-type';
import { AvailableDocumentProperty } from 'app/models/available-document-property';
import { DocumentType } from 'app/models/document-type';

export const getDocuments = (
  ids: string[] | null,
  dateFrom: string | null,
  dateTo: string | null,
  customerIds: string[] | null,
  documentType: string | null,
  missingAdditionalDocumentType: string | null,
  isActive: boolean | null,
  hasBalance: boolean | null,
  isAccepted: boolean | null,
  properties: string | null,
  page: number,
  pageSize: number,
  localeType: LocaleType,
  cancelToken: CancelToken | null
) =>
  axiosInstance
    .get<DocumentList>('/document', {
      params: {
        ids,
        dateFrom,
        dateTo,
        customerIds,
        documentType,
        missingAdditionalDocumentType,
        isActive,
        hasBalance,
        isAccepted,
        properties,
        page,
        pageSize,
        localeType,
      },
      cancelToken: cancelToken ?? undefined,
    })
    .then((response) => response.data);

export const searchDocuments = (query: string) =>
  axiosInstance
    .get<DocumentList>('/document/search', {
      params: {
        query,
      },
    })
    .then((response) => response.data);

export const getDocumentPreview = (token: string) =>
  axiosInstance
    .get<DocumentPreview>(`/document/preview/${encodeURIComponent(token)}`)
    .then((response) => response.data);

export const getDocumentPreviewList = (token: string) =>
  axiosInstance
    .get<DocumentPreviewList>(
      `/document/preview/${encodeURIComponent(token)}/documents`
    )
    .then((response) => response.data);

export const acceptPreview = (token: string) =>
  axiosInstance
    .put<void>(`/document/preview/${encodeURIComponent(token)}/accept`)
    .then((response) => response.data);

export const createDocument = (documentCreate: DocumentCreate) =>
  axiosInstance
    .post<void>('/document', documentCreate)
    .then((response) => response.data);

export const updateDocument = (id: string, documentUpdate: DocumentUpdate) =>
  axiosInstance
    .put<void>(`/document/${encodeURIComponent(id)}`, documentUpdate)
    .then((response) => response.data);

export const updateDocumentActive = (id: string, isActive: boolean) =>
  axiosInstance
    .put<void>(`/document/${encodeURIComponent(id)}/active`, isActive, {
      transformRequest: transformJsonRequest,
    })
    .then((response) => response.data);

export const accept = (id: string) =>
  axiosInstance
    .put<void>(`/document/${encodeURIComponent(id)}/accept`)
    .then((response) => response.data);

export const deleteDocument = (id: string) =>
  axiosInstance
    .delete<void>(`/document/${encodeURIComponent(id)}`)
    .then((response) => response.data);

export const getAdditionalDocumentTypes = (localeType: LocaleType) =>
  axiosInstance
    .get<AdditionalDocumentType[]>('/document/additional/type', {
      params: {
        localeType,
      },
    })
    .then((response) => response.data);

export const getAvailableProperties = () =>
  axiosInstance
    .get<AvailableDocumentProperty[]>('/document/property')
    .then((response) => response.data);

export const updateAdditionalDocument = (
  id: string,
  type: string,
  index: number,
  additionalDocumentUpdate: AdditionalDocumentUpdate
) =>
  axiosInstance
    .post<void>(
      `/document/${encodeURIComponent(id)}/additional/${encodeURIComponent(
        type
      )}/${encodeURIComponent(index)}`,
      additionalDocumentUpdate
    )
    .then((response) => response.data);

export const deleteAdditionalDocument = (
  id: string,
  type: string,
  index: number
) =>
  axiosInstance
    .delete<void>(
      `/document/${encodeURIComponent(id)}/additional/${encodeURIComponent(
        type
      )}/${encodeURIComponent(index)}`
    )
    .then((response) => response.data);

export const getDocumentTypes = (localeType: LocaleType) =>
  axiosInstance
    .get<DocumentType[]>('/document/type', {
      params: {
        localeType,
      },
    })
    .then((response) => response.data);
