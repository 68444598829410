import { AxiosRequestHeaders, AxiosRequestTransformer } from 'axios';

export const transformJsonRequest: AxiosRequestTransformer = (
  data: any,
  headers?: AxiosRequestHeaders
) => {
  if (headers) {
    headers['Content-Type'] = 'application/json;charset=utf-8';
  }

  return JSON.stringify(data);
};
